.inputSubtext {
    color: grey;
}

label {
    font-weight: 500;
    font-size: 1.2rem;
}

.totalCost[type="text"][disabled] {
    color: black;
}

.sheetCount {
    width: 100%;
}
