/* Styles used in InitiationForm.tsx */

.checkboxPadding {
    padding-top: 14px;
    padding-bottom: 10px;
    
  }
  
  /* The container Div to hold form fields and error labels. */
  .fieldDiv {
    max-height: 90px;
  }

  /* The form field. */
  .formField {
    max-height: auto;
   
    margin-top: 4px;
  }

  /* The error label to be displayed if validation
  fails, underneath the form field. */
  .errorLabel {
    margin-right:8px;
    margin-bottom: auto;
  }

  .formFieldSearch {
    max-width: 30%;
  }

  .separatorDiv {
    
    margin-left:auto;
    margin-right:auto;
  }



  

  .checkbox {
    margin-right:5px;
    margin-top:4px;
    margin-bottom:4px;
  }

  @media only screen and (max-width: 767px) {
    .separatorDiv {
      padding-bottom: 3%;
    }
    
  }